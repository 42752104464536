import React from 'react'

const Blog = () => {
  return (
    <div className='font-bold text-4xl text-center mt-[34vh] tracking-wide'>
      COMING SOON ...
    </div>
  )
}

export default Blog
