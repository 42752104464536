export const dsaplans =[
    {
        heading: "Workshops",
        details:"In this workhops, you are trained to use online AI tools and git to improve your efficiency and knowledge"
        
    },
    {
        heading: "Game development",
        details:"To improve the game development commity, we be teaching both 2d and 3d game development and how to host them"

    },
    {
        heading: "Long-term projects",
        details:"we  assign a long term projects like compiler design and more which encourage contribution of every departments"
    },
    {
        heading: "DSA series",
        details:"we teach DSA and coding through intractive sessions , coding competitions and weakly challenge problems "
    }
]