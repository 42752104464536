const TechmaniacsTeam = () => {
    return (
        <div className='TechTeam'>
            <h2>Club Head</h2>
            <div className="sep1"></div>
            <div className="leads">
                <div className="heads">
                        Chirag Kotian
                </div>
            </div>
        </div>
    )
}

export default TechmaniacsTeam