import React from 'react'
import GaganVedhiComponents from './GagdnVedhiComponents/GaganVedhiComponents'

const GaganVedhi = () => {
  return (
    // <div>GaganVedhi</div>
    <GaganVedhiComponents/>
  )
}

export default GaganVedhi